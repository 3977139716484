import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Text from "../components/text"
import Box from "../components/box"

const NowPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Seo title="now" />
      <Text
        as="h1"
        css={{ marginBottom: "$48", marginTop: "$128", fontSize: "$16" }}
      >
        What I’m doing now
      </Text>
      <Box
        as="ul"
        css={{
          li: {
            fontFamily: "$sans",
            fontSize: "$16",
          },
        }}
      >
        <li>Writing articles</li>
        <li>Building this website</li>
        <li>Working in StarOfService</li>
        <li>Helping my bonsai to start looking like a bonsai</li>
        <li>Started to do some bouldering</li>
        <li>Looking for a squash partner</li>
        <li>
          Reading books (
          <a
            href="https://literal.club/kkrll/goal/kiryl-ks-2023-reading-goal-ukcy0m3"
            target="_blank"
          >
            18
          </a>{" "}
          in 2023)
        </li>
      </Box>

      <Text
        css={{
          marginY: "$48",
          fontSize: "$14",
          fontFamily: "$sans",
        }}
      >
        Last updated May 19, 2023
      </Text>

      <Text
        css={{
          marginBottom: "$48",
          marginTop: "$128",
          fontSize: "$14",
          fontFamily: "$sans",
        }}
      >
        This is a "<a href="https://nownownow.com/about">now page</a>", and you
        should make one too.
      </Text>
    </Layout>
  )
}

export default NowPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
